import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { exportData } from '../../core/data/export';

@Component({
  selector: 'event-explorer-details',
  templateUrl: './event-explorer-details.page.html',
  styleUrls: ['./event-explorer-details.page.scss'],
})
export class EventExplorerDetailsPage implements OnInit {
  @Input() selectedEvent: any;
  restaurant: any;
  exportColumn: any;
  @Input() set restaurantDetails(val) {
    this.restaurant = val;
    this.exportColumn = [...this.exportColumn, ...val.column];
  }
  constructor(
    private modalCtrl: ModalController,
    private toastController: ToastController,
  ) {
    this.exportColumn = exportData;
  }

  ngOnInit() {
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }

  download() {
    if (!this.selectedEvent.response.file) {
      return this.toast('No Document or Image was uploaded for this activity');
    }
    this.selectedEvent.fileInfo.url.subscribe(x => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = x;
      a.target = '_blank';
      a.download = this.selectedEvent.response.file;
      a.click();
      window.URL.revokeObjectURL(x);
    });
  }


  async toast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 5000
    });
    toast.present();
  }


}

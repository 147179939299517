import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { EventExplorerDetailsPage } from './event-explorer-details.page';

const routes: Routes = [
  {
    path: '',
    component: EventExplorerDetailsPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  declarations: [EventExplorerDetailsPage]
})
export class EventExplorerDetailsPageModule {}

import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { MD5 } from 'crypto-js';
import { Lang } from '../../core/lang/lang';
import { ToastController, MenuController, AlertController, ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'restaurant-add-user',
  templateUrl: './restaurant-add-user.page.html',
  styleUrls: ['./restaurant-add-user.page.scss'],
})
export class RestaurantAddUserPage {

  name: string;
  password: string;
  phone: string;
  email: string;
  cnfpassword: string;

  constructor(
    public fireauth: AngularFireAuth,
    public db: AngularFirestore,
    private router: Router,
    private lang: Lang,
    public toastController: ToastController,
    public menuCtrl: MenuController,
    public alertController: AlertController,
    public modalController: ModalController,
    private fns: AngularFireFunctions) {

  }

  createAccount() {

    const callable = this.fns.httpsCallable('user-create');
    callable({
      emailId: this.email,
      phoneNumber: this.phone,
      password: this.password,
      userName: this.name,
      photoURL: 'https://www.gravatar.com/avatar/' + MD5(this.email).toString() + '?s=400'
    }).subscribe(x => {
      this.alert(
        'User Added Successfully',
        'Note: A verification email has been sent to the user\'s emails id. ' +
        'Please get the verification completed and inform the user, his/her user id and password'
      );
      this.toast('User Added Successfully');
      this.close({ 'email': this.email });
    });


    // this.fireauth.auth.create(this.email, this.password).then((resp) => {
    //   const user = resp.user;
    //   user.sendEmailVerification();
    //   const userCollection = this.db.collection('users');
    //   userCollection.doc(user.uid).set({
    //     userId: user.uid,
    //     userName: this.name,
    //     userEmailId: this.email,
    //     userPhone: this.phone
    //   });
    //   user.updateProfile({
    //     displayName: this.name,
    //     photoURL: 'https://www.gravatar.com/avatar/' + MD5(this.email).toString() + '?s=400'
    //   }).then(() => {
    //     this.alert('User Added Successfully', 'Note: A verification email has been sent to the user\'s emails id. '+
    //        'Please get the verification completed and inform the user, his/her user id and password');
    //     this.toast('User Added Successfully');
    //     this.close();
    //   }).catch((error) => { this.toast(this.lang.translate(error.code)); });
    // }).catch((e) => { this.toast(this.lang.translate(e.code)); });

  }

  async toast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 5000
    });
    toast.present();
  }

  async alert(heading: any, message: any) {
    const alert = await this.alertController.create({
      header: heading,
      message: message,
      buttons: ['Ok']
    });

    await alert.present();
  }

  close(resp = {}) {
    this.modalController.dismiss(resp);
  }
}
